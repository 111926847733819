/** @jsx jsx */
import { jsx, Container, Box, Grid, Heading, Text } from "theme-ui";
import { useStaticQuery, Link, graphql } from "gatsby";

const HeroArticle = ({ block }) => {
	const data = useStaticQuery(graphql`
		query RecentArticle {
			allMarkdownRemark(
				filter: {
					fields: { slug: { glob: "/posts/*" } }
					frontmatter: { draft: { ne: true } }
				}
				limit: 1
				sort: { fields: frontmatter___date, order: DESC }
			) {
				edges {
					node {
						frontmatter {
							title
							tags
							image
							date(formatString: "MMMM DD, YYYY ")
						}
						fields {
							slug
						}
						excerpt(truncate: true)
					}
				}
			}
		}
	`);
	return (
		<Box
			as="section"
			pb={block.hero_condensed ? [1, 2] : [5, 6]}
			pt={block.hero_condensed ? [4, 5, 6] : [6, 7]}
		>
			<Container px={[3, 4]}>
				<Grid gap={[3, 4]} columns={[1, 2]}>
					<Box sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
						<Heading as="h1" mb={[3, 4]} variant="text.headingSuper">
							{block.hero_heading}
						</Heading>
					</Box>
					<Grid
						columns={[1, 1, 2]}
						sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}
					>
						{block.hero_subheading && (
							<Box mb={[3, 1]}>
								<Text as="p" variant="text.bodyLarge" mr={[2, 3, 4]}>
									{block.hero_subheading}
								</Text>
							</Box>
						)}
						<Box pt={[0, 3]}>
							<Heading variant="text.headingXS" mb={[2, 3]}>
								Our latest article:
							</Heading>
							<Box>
								<Link to={data.allMarkdownRemark.edges[0].node.fields.slug}>
									<Heading variant="text.postHeading" mb={[2, 3]}>
										{data.allMarkdownRemark.edges[0].node.frontmatter.title}
									</Heading>
								</Link>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default HeroArticle;
