/** @jsx jsx */
import { jsx } from "theme-ui";
import { Container, Box, Grid, Heading } from "theme-ui";

const Columns = ({ block }) => {
	const columnTemplate = (format) => {
		switch (format) {
			case "2/3 x 1/3":
				return ["1fr", "3fr 1.5fr"];
			case "1/3 x 2/3":
				return ["1fr", "1.5fr 3fr"];
			case "50/50":
				return ["1fr", "1fr 1fr"];
			case "3/4 x 1/4":
				return ["1fr", "4fr 1fr"];
			default:
				return ["1fr", "1fr 1fr"];
		}
	};

	return (
		<Box as="section" id={block.section_id}>
			<Container px={[3, 4]} py={[5, 6]}>
				<Heading as="h2" mb={(3, 4)}>
					{block.title}
				</Heading>
				<Grid
					gap={block.columnGap ? block.columnGap : 4}
					columns={
						block.column_format
							? columnTemplate(block.column_format)
							: ["1fr", "1fr 1fr"]
					}
				>
					{block.columnContents.map((column, index) => (
						<Box key={index}>
							{column.columnHeading && (
								<Heading as="h4" variant="text.headingS">
									{column.columnHeading}
								</Heading>
							)}
							<Box dangerouslySetInnerHTML={{ __html: column.columnBody }} />
						</Box>
					))}
				</Grid>
			</Container>
		</Box>
	);
};
export default Columns;
