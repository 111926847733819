/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState } from "react";
import { Box, Button, Flex, Text } from "theme-ui";

const SliderCard = ({ data }) => (
	<Box as="blockquote" sx={{ textAlign: "left" }} mb={[3, 4]}>
		<Text variant="text.bodyLarge">{data.quote}</Text>
		<Text my={[2, 3]}>{data.byline}</Text>
	</Box>
);

const Dots = ({ index, current }) => (
	<span
		sx={{
			height: "8px",
			width: "8px",
			background: "black",
			display: "block",
			borderRadius: "50%",
			margin: "4px",
			opacity: index === current ? 1 : "0.1",
		}}
	/>
);

const Slider = ({ quotes }) => {
	const [current, setCurrent] = useState(0);
	let next = current + 1;

	function handleChange() {
		if (next === quotes.length) {
			setCurrent(0);
		} else {
			setCurrent(next);
		}
	}

	return (
		<Box
			mx="auto"
			sx={{ maxWidth: ["90%", "75%", "55%"], textAlign: "center" }}
		>
			<Flex sx={{ justifyContent: "center", mb: [3, 4] }}>
				{quotes.map((v, index) => (
					<Dots index={index} key={index.toString()} current={current} />
				))}
			</Flex>
			<SliderCard data={quotes[current]} />
			<Button variant="subdued" onClick={() => handleChange()}>
				Next
			</Button>
		</Box>
	);
};

export default Slider;
