/** @jsx jsx */

import { jsx, Flex, Heading } from "theme-ui";
import theme from "../gatsby-plugin-theme-ui";

const ArrowLinkStyle = {
	position: "relative",
	justifyContent: "flex-end",
	alignItems: "center",
};

const ArrowLink = (props) => (
	<Flex {...props} sx={ArrowLinkStyle}>
		<Heading variant="text.postHeading" pr={2}>
			{props.text}
		</Heading>
		<svg
			width="23"
			height="12"
			viewBox="0 0 23 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M0 6H22M22 6L17 1M22 6L17 11" stroke={theme.colors.primary} />
		</svg>
	</Flex>
);

export default ArrowLink;
