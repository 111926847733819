/** @jsx jsx */
import { jsx } from "theme-ui";
import { StaticQuery, graphql } from "gatsby";
import { Box, Container, Grid, Heading } from "theme-ui";
import { Post } from "../../components";

const ArticleGrid = ({ data, heading, limit, layout }) => {
	const { allMarkdownRemark } = data;
	const { edges } = allMarkdownRemark;

	return (
		<Box as="section">
			<Container px={[3, 4]} py={[3, 4]}>
				<Box mb={[2, 3]}>
					<Heading variant="text.headingXS">{heading}</Heading>
				</Box>
				<Grid
					columns={[1, 2, 3]}
					gap={[4, 5]}
					sx={{ alignItems: "flex-start" }}
				>
					{edges
						.filter((article, index) => {
							if (index < limit) {
								return article;
							} else {
								return null;
							}
						})
						.map((article, index) => (
							<Post
								key={index.toString()}
								post={article.node}
								loc={index}
								layout={layout}
							/>
						))}
				</Grid>
			</Container>
		</Box>
	);
};

const Articles = ({ block }) => (
	<StaticQuery
		query={graphql`
			query RecentArticles {
				allMarkdownRemark(
					filter: {
						fields: { slug: { glob: "/posts/*" } }
						frontmatter: { draft: { ne: true } }
					}
					limit: 5
					sort: { fields: frontmatter___date, order: DESC }
					skip: 1
				) {
					edges {
						node {
							frontmatter {
								title
								tags
								image
								date(formatString: "MMMM DD, YYYY ")
							}
							fields {
								slug
							}
							excerpt(truncate: true)
						}
					}
				}
			}
		`}
		render={(data) => (
			<ArticleGrid
				data={data}
				limit={block.article_limit}
				heading={block.article_heading}
				layout={block.article_feature_layout}
			/>
		)}
	/>
);
export default Articles;
