/** @jsx jsx */
import { jsx } from "theme-ui";
import { Box } from "theme-ui";

const imageStyling = {
	backgroundSize: "cover",
	backgroundPosition: "50%",
};

const FullImage = ({ block }) => (
	<Box
		sx={{
			...imageStyling,
			height: `${block.image_height}vh`,
			backgroundImage: `url(${block.image_source})`,
		}}
	/>
);

export default FullImage;
