import React from "react";
import { Container, Box, Heading, Text } from "theme-ui";
const Hero = ({ block }) => (
	<Box
		as="section"
		pb={block.hero_condensed ? [3, 4, 5] : [6, 7]}
		pt={block.hero_condensed ? [4, 5, 6] : [6, 7]}
	>
		<Container px={block.hero_condensed ? [5, 6] : [3, 4]}>
			<Heading as="h1" mb={[3, 4]} variant="text.headingSuper">
				{block.hero_heading}
			</Heading>
			{block.hero_subheading && (
				<Text as="p" variant="text.bodyLarge" mr={[2, 3, 4]}>
					{block.hero_subheading}
				</Text>
			)}
		</Container>
	</Box>
);

export default Hero;
