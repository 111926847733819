/** @jsx jsx */
import { jsx } from "theme-ui";
import { Box, Container, Grid, Heading, Text, Flex, Link } from "theme-ui";

import TeamData from "../../data/team.json";

const Social = ({ social }) => (
	<Link
		className="button is-light"
		style={{ marginRight: "0.25em", marginBottom: "0.25em" }}
		title={social.platform}
		href={social.link}
		target="_blank"
		rel="noopener noreferrer"
		variant="buttons.subdued"
	>
		{social.platform}
	</Link>
);

const photoStyle = {
	maxWidth: "120px",
	borderRadius: "50%",
	overflow: "hidden",
};

const Teammate = ({ member }) => (
	<Box>
		<Box className="team-photo-container" sx={photoStyle} mb={[3, 4]}>
			<img
				className="team-photo"
				src={member.photo}
				alt={member.name}
				sx={{ width: "100%" }}
			/>
		</Box>
		<Heading as="h3" variant="text.headingM">
			{member.name}
		</Heading>
		<Text as="p" mb={[2, 3]}>
			{member.job_title}
		</Text>
		<Text as="p" mb={[3, 4]}>
			{member.bio}
		</Text>
		{/* <Heading as="h5" variant="text.headingXS">
			Connect with me:
		</Heading> */}
		<Flex sx={{ justifyContent: "flex-start", flexWrap: "wrap" }} my={[3]}>
			{member.social.map((social, index) => (
				<Social key={index} social={social} />
			))}
		</Flex>
	</Box>
);

const Team = ({ block }) => {
	return (
		<Box as="section" id="team">
			<Container px={[3, 4]} py={[5, 6]}>
				{block.team_headline && (
					<Box mb={(4, 5)}>
						<Heading mb={(2, 3)}>{block.team_headline}</Heading>
					</Box>
				)}
				<Grid columns={[1, 1, block.team_columns]} gap={[2, 3, 5, 6]}>
					{TeamData.map((member, index) => (
						<Teammate key={index} member={member} isLast={index} />
					))}
				</Grid>
			</Container>
		</Box>
	);
};

export default Team;
