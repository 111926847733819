/** @jsx jsx */
import { jsx } from "theme-ui";
import { Box, Flex, Container, Heading, Button } from "theme-ui";

function isExternal(url) {
	if (url.includes("https")) {
		return true;
	} else {
		return false;
	}
}

const CallToAction = ({ block }) => (
	<Box bg={block.cta_background ? block.cta_background : "secondary"}>
		<Container px={[3, 4]} py={[4, 5]}>
			<Flex
				sx={{
					flexDirection: ["column"],
					alignItems: "center",
					justifyContent: "center",
					flexWrap: "wrap",
				}}
			>
				<Heading color="white" m={[3, 4]}>
					{block.cta_heading}
				</Heading>

				<Button
					as="a"
					variant="ghost"
					href={block.cta_button_url}
					title={block.cta_button_label}
					rel={isExternal(block.cta_button_url) ? "noopener" : ""}
					target={isExternal(block.cta_button_url) ? "_blank" : ""}
					mx={[3, 4]}
				>
					{block.cta_button_label}
				</Button>
			</Flex>
		</Container>
	</Box>
);

export default CallToAction;
