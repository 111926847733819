/** @jsx jsx */
import { jsx } from "theme-ui";
import { Container, Box, Grid, Heading, Text } from "theme-ui";
import Feature from "./inc/_Feature";

const Features = ({ block }) => (
	<Box as="section" id={block.feature_id}>
		<Container px={[3, 4]} py={[5, 6]}>
			{block.feature_headline && (
				<Box mb={(4, 5)}>
					<Heading variant="text.headingL" mb={(2, 3)}>
						{block.feature_headline}
					</Heading>
					{block.feature_subhead && (
						<Text variant="text.bodyLarge">{block.feature_subhead}</Text>
					)}
				</Box>
			)}
			<Grid gap={[3, 4]} columns={[1, 2]}>
				{block.features.map((feature, index) => (
					<Feature key={index} feature={feature} />
				))}
			</Grid>
		</Container>
	</Box>
);

export default Features;
