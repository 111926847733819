/** @jsx jsx */
import _ from "lodash";
import { Link } from "gatsby";
import { jsx, Container, Box, Heading, Grid } from "theme-ui";
import ArrowLink from "../ArrowLink";
import theme from "../../gatsby-plugin-theme-ui/";

const topicHeadingStyle = {
	backgroundColor: theme.colors.white,
	padding: "0.5em 1em",
	textAlign: "right",
	position: "absolute",
	bottom: 0,
};

const Topics = ({ block }) => {
	return (
		<Box as="section" id="topics">
			<Container px={[3, 4]} py={[5, 6]}>
				<Box mb={[2, 3]}>
					<Heading variant="headingXS">{block.topics_heading}</Heading>
				</Box>
				<Grid
					columns={[1, "2fr 1fr", "2fr 1fr 1fr"]}
					gap={[3, 4]}
					sx={{ alignItems: "flex-start" }}
				>
					{block.topics_list.map((t, index) => (
						<Box
							as={Link}
							to={`/tags/${_.kebabCase(t.topic_name)}`}
							key={index}
							sx={
								index === 0
									? { gridRowStart: 1, gridRowEnd: 3, position: "relative" }
									: { position: "relative" }
							}
						>
							<img src={t.topic_image} alt={t.topic_name} />
							<ArrowLink sx={topicHeadingStyle} text={t.topic_name} />
						</Box>
					))}
				</Grid>
			</Container>
		</Box>
	);
};

export default Topics;
