import React from "react";
import { graphql } from "gatsby";

import {
	Hero,
	HeroArticle,
	Features,
	Columns,
	CallToAction,
	Team,
	Testimonials,
	Articles,
	Topics,
	FullImage,
} from "../components/blocks/";
import Layout from "../components/global/Layout";

const BlocksTemplate = ({ data }) => {
	const post = data.markdownRemark;
	const { markdownRemark } = data;
	const { html } = markdownRemark;

	return (
		<Layout>
			{post.frontmatter.blocks.map((block, index) => {
				switch (block.component) {
					case "hero":
						return <Hero key={index} block={block} />;
					case "heroArticle":
						return <HeroArticle key={index} block={block} />;
					case "features":
						return <Features key={index} block={block} />;
					case "columns":
						return <Columns key={index} block={block} />;
					case "cta":
						return <CallToAction key={index} block={block} />;
					case "team":
						return <Team key={index} block={block} />;
					case "testimonials":
						return <Testimonials key={index} block={block} />;
					case "articles":
						return <Articles key={index} block={block} />;
					case "topics":
						return <Topics key={index} block={block} />;
					case "fullImage":
						return <FullImage key={index} block={block} />;
					default:
						return "";
				}
			})}
			{html}
		</Layout>
	);
};

export default BlocksTemplate;

export const query = graphql`
	query BlockPageBySlug($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				draft
				blocks {
					template
					component
					background
					title
					columnGap
					feature_headline
					feature_id
					feature_subhead
					features {
						feature_description
						feature_image
						feature_title
					}
					hero_heading
					hero_subheading
					hero_condensed
					hero_article_title
					hero_article_link
					section_id
					image_source
					image_height
					column_format
					columnContents {
						columnBody
						columnHeading
					}
					cta_heading
					cta_background
					cta_arrangement
					cta_button_label
					cta_button_url
					cta_button_color
					team_data
					team_headline
					team_columns
					testimonial_headline
					article_limit
					article_heading
					article_feature_layout
					topics_list {
						topic_name
						topic_image
					}
					topics_heading
				}
			}
			fields {
				slug
			}
		}
	}
`;
