/** @jsx jsx */
import { jsx } from "theme-ui";
import { Container, Box, Heading } from "theme-ui";

import Slider from "../slider";
import QuoteData from "../../data/testimonials.json";

const Testimonials = ({ block }) => (
	<Box as="section" id="testimonials">
		<Container px={[3, 4]} py={[5, 6]}>
			<Heading sx={{ textAlign: "center", marginBottom: 5 }}>
				{block.testimonial_headline}
			</Heading>
			<Slider quotes={QuoteData} />
		</Container>
	</Box>
);

export default Testimonials;
