import React from "react";
import { Grid, Heading, Box, Text } from "theme-ui";

const Feature = ({ feature }) => (
	<Grid gap={[3, 2]} columns={["1fr 6fr", "1fr 3fr", "1fr 5fr"]} mb={[4, 5]}>
		{feature.feature_image && (
			<Box mr={3}>
				<img src={feature.feature_image} alt={feature.feature_title} />
			</Box>
		)}
		<Box>
			<Heading as="h4" mb={2}>
				{feature.feature_title}
			</Heading>
			<Text>{feature.feature_description}</Text>
		</Box>
	</Grid>
);

export default Feature;
